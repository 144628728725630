<template>
  <div style="background: #e2e2e2">
    <div class="header-solutions">
      <span class="header-font">Soluciones</span>
    </div>
    <Brochure></Brochure>
    <ContactForm></ContactForm>
    <ContactBanner></ContactBanner>
  </div>
</template>

<script>
export default {
  name: "SolutionsView",
  components: {
    Brochure: () => import("@/components/new-page/Brochure"),
    ContactForm: () => import("@/components/new-page/ContactForm"),
    ContactBanner: () => import("@/components/new-page/ContactBanner"),
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
  created() {},
};
</script>

<style scoped>
.header-solutions {
  width: 100%;
  height: 140px;
  background: #006ec8;
  border-bottom-right-radius: 40px;
  display: flex;
  align-items: center;
}
.header-font {
  margin-left: 10%;
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 100%;
  color: #ffffff;
}
</style>